<template>
  <div class="desktopLayoutClass" id="heatMapWrapper">
    <v-app>
      <!-- Dialog Start -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                    {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div class="Maindivbooking">

        <div v-show="show_header" class="header" style="display: flex; flex-direction: row;">
          <div style="display: flex; flex-direction: row; width: 100%">
            <div style="width: 10%; padding-top: 6px" align="left" v-on:click="goBack()">
              <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
            </div>
            <div style="width: 90%" align="left">
              <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.MyBookings.Booking_Details") }}</span>
            </div>
          </div>
        </div>

        <div style="margin-top: 10px;">
          <v-col cols="12">
            <v-tabs v-model="tab" background-color="white" fixed-tabs style="border: white solid 2px;" class="appointmentTab">
              <v-tabs-slider color="transparent"></v-tabs-slider>
              <v-tab v-for="item in items" :key="item.tab" style="width: 50%; border-radius: 8px;" active-class="activeClass">
                <span v-if="item.tab == 'Upcoming'">{{ $t("Customer.MyBookings.Upcoming") }}</span>
                <span v-if="item.tab == 'Completed'">{{ $t("Customer.MyBookings.Completed") }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <div>
                <v-tab-item style="overflow: scroll; height: 80vh; padding-bottom: 20px;">
                  <div v-if="!isLoadingAppointment">
                    <div style="padding-bottom:20px;" v-if="upcoming_appointments_list.length > 0">
                      <div class="py-2 singleAppointment" v-for="appointment in upcoming_appointments_list" :key="appointment._id">
                        <BookingCard :appointment="appointment" :completed_status="false" :upcoming_status="true"/>
                      </div>
                    </div>
                    <div v-else>
                      <p style="margin-top: 10%;">{{ $t("Customer.MyBookings.No_Future_Appointments") }}</p>
                    </div>
                  </div>
                  <div  style="margin-top: 10%;" v-else>
                    <v-card>
                      <div style="display:flex; flex-direction:row;justify-content: space-around; margin-top:15px ">
                        <div>
                          <v-skeleton-loader class="mx-auto" max-width="300" type="avatar"></v-skeleton-loader>
                        </div>
                        <div>
                          <v-skeleton-loader class="mx-auto" width="150px" type="text" style="margin-top:25px"></v-skeleton-loader>
                        </div>
                        <div>
                          <v-skeleton-loader class="mx-auto" max-width="300" style="margin-top:10px" type="button"></v-skeleton-loader>
                        </div>
                      </div>
                      <div style="display:flex; flex-direction:row;justify-content: space-evenly; ">
                      <div>
                        <v-skeleton-loader
                          class="mx-auto"
                          width="150px"
                          type="list-item-two-line"
                          ></v-skeleton-loader>
                        </div>
                        <div>
                          <v-skeleton-loader
                          class="mx-auto"
                          width="150px"
                          type="list-item-two-line"
                          ></v-skeleton-loader>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </v-tab-item>
              </div>
              <div>
                <v-tab-item style="overflow:scroll; height: 80vh;padding-bottom: 20px;">
                  <div v-if="!isLoadingAllAppointment">
                    <div v-if="all_appointments_list.length > 0">
                        <div class="py-2 singleAppointment" v-for="appointment in all_appointments_list" :key="appointment._id">
                          <BookingCard :all_bookings_appointment="all_bookings_appointment" :appointment="appointment" :completed_status="true" :upcoming_status="false"/>
                        </div>
                    </div>
                    <div v-else>
                      <p style="margin-top: 10%;">{{ $t("Customer.MyBookings.No_Past_Appointments") }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <v-card class="HomeCardClass" style="width:90%; height: 90%; border-radius: 8px;margin-top: 20px;padding:10px;">
                      <div style="display:flex; flex-direction:row;justify-content: space-around; margin-top:15px " >
                        <div>
                          <v-skeleton-loader class="mx-auto" max-width="300" type="avatar"></v-skeleton-loader>
                        </div>
                        <div>
                          <v-skeleton-loader class="mx-auto" width="150px" type="text" style="margin-top:25px"></v-skeleton-loader>
                        </div>
                        <div>
                          <v-skeleton-loader class="mx-auto" max-width="300" style="margin-top:10px" type="button"></v-skeleton-loader>
                        </div>
                      </div>
                      <div style="display:flex; flex-direction:row;justify-content: space-evenly; ">
                        <div>
                          <v-skeleton-loader class="mx-auto" width="150px" type="list-item-two-line"></v-skeleton-loader>
                        </div>
                      <div>
                        <v-skeleton-loader class="mx-auto" width="150px" type="list-item-two-line"></v-skeleton-loader>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </v-tab-item>
              </div>
            </v-tabs-items>
          </v-col>
        </div>

        <!-- <bottomnavbar
        style="position: sticky; bottom: 0px; width: 100%"
        :pageName="pageName" :isLoggedIn=currentCustomer :kbzEmployee=isKbzEmployee
        ></bottomnavbar> -->
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import bottomnavbar from "./bottomnavbar.vue";
import DateRendered from './dateRender.vue';
import TimeRender from './TimeRender.vue';
import BookingCard from './bookingcardDetails.vue';
import * as d3 from 'd3';
import * as h337 from 'heatmap.js';
import { local } from "d3";

export default {
  name: "CustomerHome",
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      upcoming_appointments_list: [],
      all_appointments_list: [],
      completed_appointments_list: [],
      user_id: "",
      pageName: "BookingPage",
      tab:null,
      isLinkActive:false,
      defaultClass:true,
      currentCustomer : "",
      customer_name : "",
      prescription_reference_number:"",
      items:[{ tab: 'Upcoming', content: 'Upcoming Appoitment' },
      { tab: 'Completed', content: 'Completed Appoitmanet' },],
      isMobile: false,
      isLoadingAppointment:true,
      isLoadingAllAppointment:true,
      isKbzEmployee: false,
      show_header: true,
      all_bookings_appointment:false,
      isprescription:null,
      prescriptionFlag: false,
      app_refrence_no:'',
    };
  },
  mounted() {
    var userAgent = navigator.userAgent

    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    if(window.xm){
      document.title = '';
    } else {
    if(this.$store.state.locale == 'en')
      document.title = "My Appointments"
    else
      document.title = 'ဘွတ်ကင်များ'
    }

    this.isMobile = this.$device.mobile;
    this.$i18n.locale = this.$store.state.locale;
    this.isKbzEmployee = this.$store.state.employee;
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
    if(!this.currentCustomer){
      this.$router.push({
        name : "Login"
      })
    }
    else{
      //logging page hit - start
      let x =  localStorage.getItem("currentURL")
      let pageName = "MyBookingsScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
      //logging page hit - end
        var myBookingsData = {
          "token" : this.currentCustomer,
          "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL +"/myBookingsPage",myBookingsData)
        .then((myBookingsResponse) => {
          //console.log(myBookingsResponse.data)
          this.isKbzEmployee = myBookingsResponse.data.customer.kbzEmployee;
          // this.user_id = myBookingsResponse.data.customer._id;
          this.customer_name = myBookingsResponse.data.customer.customer_name;
          this.upcoming_appointments_list = myBookingsResponse.data.upcoming;
          //this.isprescription=myBookingsResponse.data.completed;
          //console.log(this.isKbzEmployee, this.user_id, this.customer_name, this.upcoming_appointments_list, this.isprescription);
          this.upcoming_appointments_list.forEach((x) => {
            var get_diff = new Date(x.booking_epoch_time).getTime() - new Date().getTime();
            var end_diff = new Date((x.booking_closing_epoch_time)) - new Date().getTime();
          //var end_diff = new Date(new Date(x.date_of_appointment+" "+x.time_slot.split("-")[1]+":00 GMT+0630").getTime() + (3 * 60 * 1000)) - new Date().getTime();
            x.isLinkActive = (get_diff < 600000) ? true : false;
          });
          this.isLoadingAppointment = false
          this.all_appointments_list = myBookingsResponse.data.completed;
          this.all_appointments_list.forEach((ap, idx)=>{
            ap.isprescription=myBookingsResponse.data.completed[idx].prescription_reference_number;
            if (ap.isprescription!=''){
              ap.prescriptionFlag=true
              ap.app_refrence_no=myBookingsResponse.data.completed[idx].appointment_reference_number;
            }
            else{
              this.prescriptionFlag=false
            }
          })
          this.isLoadingAllAppointment = false;
          this.userPageViewTable();
          if(localStorage.getItem('showHeatMap')){
            this.drawHeatMap();
          }
        })
        .catch((myBookingsError) => {
          if(myBookingsError.response.status == 401){
            this.auth_error_enable = true;
          }
          else{
            this.error_enable = true;
          }
        })
       }
  },
  components: {
    NavBar,
    bottomnavbar,
    DateRendered,
    TimeRender,
    BookingCard,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    drawHeatMap(){
      var holder = d3.select('#heatMapWrapper').append('svg')
      var getAllLogsFromDBBody = {
        token: this.currentCustomer,
        pageName: 'MyBookings'
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAllLogsFromDB", getAllLogsFromDBBody)
      .then((getAllLogsFromDBResponse)=>{
        if (getAllLogsFromDBResponse.data.data.length !== 0){
          let dataPoint = getAllLogsFromDBResponse.data.data
          let maxValue =  Math.max.apply(Math, dataPoint.map(function(o) { return o.value; }))
          let minValue =  Math.min.apply(Math, dataPoint.map(function(o) { return o.value; }))
          var heatMapConfig = h337.create({
            container: document.getElementById('heatMapWrapper'),
            radius: 33,
            blur: .95
          })
          let sampleData = {
            max: maxValue,
            min: minValue,
            data: dataPoint
          }
          heatMapConfig.setData(sampleData)
        }
      })
      .catch((error)=>{
        console.log("Error")
      })
    },
    onClick(e){
        var createClickLogBody = {
          token: this.currentCustomer,
          typeOfUser: 'CUSTOMER',
          clientX: String(e.clientX),
          clientY: String(e.clientY),
          pageName: 'MyBookings'
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/createClickLog",createClickLogBody)
        .then((createClickLogResponse)=>{
          // console.log('createClickLogResponse', createClickLogResponse)
        })
        .catch((createClickLogError) => {

        });
    },
    redirecttoViewPage(booking_id) {
            this.$router.push({
        path: "viewAppointment/" + booking_id,
      });
    },

    closeDialogUnauthorized(){
    this.auth_error_enable = false;
    this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },

    redirectToHomePage() {
      this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    redirectToCallpage(meetingId) {
      this.$router.push({
       path: '/customer/videocallTwilio/'+ meetingId
     })
    },
    userPageViewTable() {
        if (this.currentCustomer === null) {
            var userPageViewTableBody = {
            pageName : 'myBookings',
            typeOfUser: 'CUSTOMER'
            }
        } else {
            var userPageViewTableBody = {
            pageName: 'myBookings',
            typeOfUser: 'CUSTOMER',
            token: this.currentCustomer
            }
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
        .catch((userPageViewTableError) => {
            console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
        });
    },
  }
};
</script>

<style scoped>
.v-application--wrap {
  min-height: none !important;
}
td, th {
  padding: 10px;
}

.singleAppointment {
  max-height: 150px !important;
}

.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
  background-color: white;
}
.toggleClass{
  justify-content: center;
  margin-left: 24%;
  width: 75%;


}
.defaultClass{
  /* width: 80%; */
  border-radius: 8px !important;
  background-color: white;
  color: black;


}


.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 48px !important;
}
.activeClass{
  border-radius: 8px !important;
  color: white !important;
  background-color:#1467BF !important;
}
.cardClass{
  margin-top: 5%;
  height: 100%;

}
.card-footer{
  position: relative;
  align-content:center;
  background-color: #fecdbe;
  padding-top: -1vh;
  margin: 0vh 0vh 0vh -2vh;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: center;
  width: 90vw;
  height: 4vh;
  font-size: 9pt;
}

::-webkit-scrollbar {
    display: none;
}
/* @media (min-width: 800px){
  .Maindivbooking{
   width: 425px !important;
    margin-left: auto !important ;
    margin-right: auto !important ;
  }
   .spacing{
    height: 190px !important;
    margin-bottom: 10%;
  }
  .HomeCardClass{
    width: 80% !important;
  }
  .homeUserPngClass{
    padding-left: 30px !important;
  }
.homeNameCardCalss{
  margin-top:16px !important;
  margin-left: 0px !important;
  float:left;
}
.homeBtnClass{
  height: 60px !important;
}
.btnHomeClass{
  width: 150px !important;
}
.timeClass{
  float: right;
  margin-left: 45%;

}
.dateClass{
  margin-right: 10% !important;
}
.timeValueClass{
  margin-left:20% !important;
}
.dateValueClass{
  float: left !important;
  margin-left: 31px !important;
}
.dateClass1{
  padding-left: 20px;

}
.TimeText{
  margin-right: 30% !important;
}

  } */
</style>
<style>
.appointmentTab .v-tabs-bar {
  height: 32px !important;
}
</style>